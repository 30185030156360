import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'
 
Vue.use(VueClipboard)
axios.defaults.baseURL = 'https://marathonapi.dailyresource.online/'
// axios.defaults.baseURL = 'http://localhost:9999/'
Vue.config.productionTip = false
//设置axios的请求头
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  config.headers['Content-Type'] = 'application/json; charset=utf-8'
  return config
})
Vue.prototype.$http = axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
