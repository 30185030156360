import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/pic',
    name: 'pic_index',
    component: () => import('../views/PicIndex.vue')
  },
  {
    path: '/pic_home',
    name: 'pic_home',
    component: () => import('../views/PicHome.vue')
  },
  {
    path: '/video',
    name: 'video_index',
    component: () => import('../views/VideoIndex.vue')
  },
  {
    path: '/video_home',
    name: 'video_home',
    component: () => import('../views/VideoHome.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/download/:mid',
    name: 'download',
    component: () => import('../views/Download.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/' || to.path === '/admin' || to.path === '/pic' || to.path === '/video') return next()
  next()
})

export default router
